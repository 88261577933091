import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "./api.service";




@Injectable({
    providedIn: 'root'
})


export class CategoriesApiService {



    constructor (private httpClient: HttpClient) {

    }


    get_categories_count (query): Observable<any> {
      return this.httpClient.get<any>(`${APIService.categories_count}?filter=${query}`);
   }

    get_categories (query): Observable<any> {
        return this.httpClient.get<any>(`${APIService.categories}?filter=${query}`);
    }

    create_categories (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.categories}`, dataDto);
    }

    patch_categories (dataDto): Observable<any> {
      return this.httpClient.patch<any>(`${APIService.categories}/${dataDto.id}`, dataDto);
    }

    get_categories_wo_token  (query): Observable<any> {
        return this.httpClient.get<any>(`${APIService.categories_wo_token}?filter=${query}`);
    }
}
