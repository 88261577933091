import { Directive } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

@Directive({
  selector: 'form',
  inputs: ['formGroup']
})
export class FormDirective {
  formGroup: FormGroup
  constructor() {


   }

   ngOnInit(): void {
    //  setTimeout(function() =)

   }

   ngAfterContentInit(): void {
     //Called after ngOnInit when the component's or directive's content has been initialized.
     //Add 'implements AfterContentInit' to the class.
    //  this.setValidators(this.formGroup);
   }

   setValidators(fb: FormGroup): void {
     (Object.keys(fb.controls)).forEach((key: string) => {
        const abstractControl = fb.get(key);
        if(abstractControl instanceof FormControl){
          const oldValidators: Array<any> = (abstractControl as any)?._rawValidators || [];
          abstractControl.setValidators([this.noWhitespaceValidator , ...oldValidators]);
          abstractControl.updateValueAndValidity();
        }else if(abstractControl instanceof FormGroup){
          this.setValidators(abstractControl);
        }else if(abstractControl instanceof FormArray){
          (abstractControl as FormArray).controls.forEach(ctrl => {
            if(ctrl instanceof FormControl){
              const oldValidatorsCtrl = (abstractControl as any)._rawValidators || [];
              ctrl.setValidators([this.noWhitespaceValidator , ...oldValidatorsCtrl]);
              ctrl.updateValueAndValidity();
              console.log(ctrl);
            }else if(ctrl instanceof FormGroup){
              this.setValidators(ctrl);
            }
          })
        }
     })
   }

   public noWhitespaceValidator(control: FormControl): ValidationErrors | null  {
    const isWhitespace =  control.value && control.value.length ?  (control.value).trim().length === 0 : false;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
