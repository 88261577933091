import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class AddressApiService {

  constructor(private httpClient: HttpClient) { }

  getAddress(data){
    return this.httpClient.get(APIService.addresses);
  }

  getAddressById(id){
    return this.httpClient.get(APIService.addresses + `/${id}`);
  }

  getAddressFilter(query): Observable<any>{
    return this.httpClient.get<any>(APIService.addresses + `?filter=${query}`);
  }

  getContries(){
    return this.httpClient.get(APIService.countries);
  }

  getCities(query){
    return this.httpClient.get(APIService.cities + `?filter=${query}`);
  }

  getStates(query){
    return this.httpClient.get(APIService.states + `?filter=${query}`)
  }

  createAddress(data){
    return this.httpClient.post(APIService.addresses, data);
  }

  updateAddress(data){
    return this.httpClient.patch(`${APIService.addresses}/${data.id}`, data);
  }
}
