import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDirective } from './form-dir.directive';
import { EmailUniqueDirective } from './email-unique.directive';



@NgModule({
  declarations: [
    FormDirective,
    EmailUniqueDirective
  ],
  imports: [
    CommonModule
  ],exports: [
    FormDirective,
    EmailUniqueDirective
  ],
  entryComponents: [FormDirective]
})
export class FormDirModule { }
