import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "./api.service";




@Injectable({
    providedIn: 'root'
})

export class ReviewApiService {



    constructor (private httpClient: HttpClient) {

    }


    create_review (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.Reviews}`, dataDto);
    }

    get_review (query): Observable<any> {
        return this.httpClient.get<any>(`${APIService.Reviews}?filter=${query}`);
    }

    get_review_count (query): Observable<any> {
        return this.httpClient.get<any>(`${APIService.Reviews_count}?where=${query}`);
    }

    update_review (dataDto): Observable<any> {
        return this.httpClient.patch<any>(`${APIService.Reviews}/${dataDto['id']}`, dataDto);
    }

}