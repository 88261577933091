import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "./api.service";




@Injectable({
    providedIn: 'root'
})


export class CartItemApiService {



    constructor (private httpClient: HttpClient) {

    }


    create_Cart_items (data): Observable<any> {
        return this.httpClient.post<any>(`${APIService.Cart_items}`, data);
    }


    get_Cart_items (query): Observable<any> {
        return this.httpClient.get<any>(`${APIService.Cart_items}/?filter=${query}`);
    }


    delete_Cart_items (id): Observable<any> {
        return this.httpClient.delete<any>(`${APIService.Cart_items}/${id}`);
    }

}