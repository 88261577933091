import { Directive, Self } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgControl, ValidationErrors } from '@angular/forms';
import { UniqueEmaiService } from './unique-email.service';

@Directive({
  selector: '[emailUnique]'
})
export class EmailUniqueDirective {

  constructor( private ngControl: NgControl, private uniqueEmaiService: UniqueEmaiService) {
   }

   ngOnInit(): void {
    console.log(this.ngControl.control.asyncValidator, '***');
    this.ngControl.control.setAsyncValidators([this.uniqueEmaiService.uniqueValidator.bind(this.uniqueEmaiService)])
   }

   ngAfterContentInit(): void {

   }


}
