import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "./api.service";




@Injectable({
    providedIn: 'root'
})


export class SubCategoriesApiService {



    constructor (private httpClient: HttpClient) {

    }


    get_categories_count (query): Observable<any> {
      return this.httpClient.get<any>(`${APIService.sub_categories_count}?filter=${query}`);
   }

    get_categories (query): Observable<any> {
        return this.httpClient.get<any>(`${APIService.sub_categories}?filter=${query}`);
    }


    create_categories (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.sub_categories}`, dataDto);
    }

    patch_categories (dataDto): Observable<any> {
      return this.httpClient.patch<any>(`${APIService.sub_categories}/${dataDto.id}`, dataDto);
   }
}
