import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "./api.service";




@Injectable({
    providedIn: 'root'
})


export class ContactUsService {

    emailPattern = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';

    constructor (private httpClient: HttpClient) {

    }


    create_contact_us (dataDto): Observable<any> {
        return this.httpClient.post<any> (`${APIService.contactUs}`, dataDto);
    }

    get_contact_us(query){
      return this.httpClient.get<any>(`${APIService.contactUs}?where=${query}`);
    }

    delete_contact_us(id){
      return this.httpClient.delete<any>(`${APIService.contactUs}/${id}`);
    }

}
