import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from './api.service';




@Injectable({
    providedIn: 'root'
})

export class StripePaymentApiService {



    constructor (private httpClient: HttpClient) {

    }


    StripePayments_onboardVendor (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.StripePayments_onboardVendor}`, dataDto);
    } 

    StripePayments_onboardVendorRefresh (dataDto): Observable<any>{
        return this.httpClient.post<any>(`${APIService.StripePayments_onboardVendorRefresh}`, dataDto);
    }


    StripePayment (query): Observable<any> {
        return this.httpClient.get<any>(`${APIService.StripePayments}?filter=${query}`);
    }


    // StripePayments_customerPlaceOrder (dataDto): Observable<any> {
    //     return this.httpClient.post<any>(`${APIService.StripePayments_customerPlaceOrder}`, dataDto);
    // }

    StripePayments_getConnectedAccountData (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.StripePayments_getConnectedAccountData}`, dataDto);
    }


    StripePayments_getConnectedAccount_loginLink (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.StripePayments_getConnectedAccount_loginLink}`, dataDto);
    }

    Orders_paymentStatus (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.Orders_paymentStatus}`, dataDto);
    }



    StripePayments_getStripeCustomerCard (dataDto): Observable<any> {
        return this.httpClient.post<any>(`${APIService.StripePayments_getStripeCustomerCard}`, dataDto);
    }
}