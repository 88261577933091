import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class ServiceProviderWebApiService {

  constructor(private httpClient: HttpClient) { }

  getServiceProvider(query): Observable<any>{
    return this.httpClient.get<any>(`${APIService.serviceProviderWeb}?filter=${query}`);
  }

  createServiceProvider(data){
    return this.httpClient.post(APIService.serviceProviderWeb, data);
  }

  updateServiceProvider(data){
    return this.httpClient.patch(`${APIService.serviceProviderWeb}/${data.id}`, data);
  }

  getServiceProviderCount (query): Observable<any> {
    return this.httpClient.get<any>(`${APIService.serviceProviderWebCount}?where=${query}`)
  }


  getServiceProviderById (id) {
    return this.httpClient.get(`${APIService.serviceProviderWeb}/${id}`)
  }

}
