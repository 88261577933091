import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminSettingApiService {
  constructor(private httpClient: HttpClient) {}

  getAdminSetting(query): Observable<any> {
    return this.httpClient.get<any>(`${APIService.AdminSetting}`);
  }

  create_admin_setting(data): Observable<any> {
    return this.httpClient.post<any>(`${APIService.AdminSetting}`, data);
  }

  update_admin_setting(data): Observable<any> {
    return this.httpClient.patch<any>(
      `${APIService.AdminSetting}/${data.id}`,
      data
    );
  }

  get_adminSetting_termCondition(): Observable<any> {
    return this.httpClient.get<any>(`${APIService.AdminSetting_termCondition}`);
  }

  get_adminSetting_privacyPolicy(): Observable<any> {
    return this.httpClient.get<any>(`${APIService.AdminSetting_privacyPolicy}`);
  }
  get_adminSetting_userInfo(): Observable<any> {
    return this.httpClient.get<any>(`${APIService.AdminSetting_userInfo}`);
  }
  get_adminSetting_providersInfo(): Observable<any> {
    return this.httpClient.get<any>(`${APIService.AdminSetting_providersInfo}`);
  }
}
