import { Injectable } from "@angular/core";




@Injectable({
    providedIn: 'root'
})


export class CommonHelperService {

    public orderStatus = [
        {
            slug: 'orderRequested',
            viewStatus: 'Order Requested'
        },
        {
            slug: 'serviceConfirmed',
            viewStatus: 'Service Confirmed'
        },
        {
            slug: 'paymentDone',
            viewStatus: 'Payment Done'
        },
        {
            slug: 'providerOnTheWay',
            viewStatus: 'Provider on the way'
        },
        {
            slug: 'orderInProgress',
            viewStatus: 'Order In Progress'
        },
        // {
        //     slug: 'payToCompleteOrder',
        //     viewStatus: 'Pay To Complete Order'
        // }, 
        {
            slug: 'orderCompleted',
            viewStatus: 'Order Completed'
        },  
        // {
        //     slug: 'cancelOrder',
        //     viewStatus: 'cancelOrder'
        // },        
    ]


    constructor () {

    }


}