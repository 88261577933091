import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})

export class PaginationService {
    
    public total_count:any = 0;
    public skip_data = 0;
    public total_skip_data = 0;
    public total_page:any = 0;
    public pagination_dropdown: any = [10, 20, 40, 80, 100];
    public selected_drop_down_data = 10;
    public pageNo:any = 1;


    paginationConfig = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 0
    }

    constructor() {

    }

    set_count_data (count, skip_count) {
        this.total_count = count;    
        this.skip_data = skip_count;        
        this.total_page = this.total_count / this.selected_drop_down_data;
        this.total_page = Math.ceil(this.total_page);
        if ( this.total_page < this.pageNo ) {
            this.pageNo = this.total_page;
            if ( this.pageNo <=0 ) this.pageNo = 1; 
            this.skip_data = this.pageNo * this.selected_drop_down_data - this.selected_drop_down_data;
            if ( this.skip_data < 0 ) this.skip_data = 0;
            // this.commonHelperService.filter.skip = this.skip_data;
        }

        this.paginationConfig.totalItems = this.total_count;

    }

   

    method_for_making_array_drop_down () {
        
    }

    method_for_increase_decrease(value) {

    }

    method_for_reseting_pagination_value () {
        this.total_count = 0;
        this.skip_data = 0;
        this.total_skip_data = 0;
        this.total_page = 0;
        this.selected_drop_down_data = 10;
        this.pageNo = 1;
    }




}