import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { APIService } from "src/app/commonServices/api.service";




@Injectable({
    providedIn: 'root'
})


export class UniqueEmaiService {

    emailPattern = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';

    constructor (private apiSerivice: APIService,
        private httpClient: HttpClient) {

    }


    check_unique_email (dataDto): Observable<any> {
        return this.httpClient.post<any> (`${APIService.uniqueEmail}`, dataDto);
    }

    uniqueValidator(control: AbstractControl) {
      const name = control.value;
      return this.check_unique_email({email:name}).pipe(map(res => res.unique ? null : {unique: true}), debounceTime(1000))
    }


}
